import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./pictureAndList.module.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import usePerksPictures from "../usePerksPictures"
import CarouselPerks from "./carouselPerks"

const PictureAndList = () => {

  const intl = useIntl();

  const imgArray = usePerksPictures();
  const [indexImage, setIndexImage] = React.useState(0)

  return (
    <section className={styles.section}>
      <div className={`row ${styles.mainRow}`}>
        <div className={`col-md-6 d-none d-md-flex ${styles.left}`}>
          <div className={styles.preview}>
            <div className={styles.limitWidth}>
              <GatsbyImage
                image={getImage(imgArray[indexImage])}
                loading="eager"
                alt={imgArray[indexImage][intl.locale + "_alt"]}
                placeholder="none"
                layout="constrained"
                quality={95}
                className={styles.previewImage}
              />
            </div>
          </div>
        </div>
        <div className={`col-md-6 ${styles.right}`}>
          <div className={styles.rightColumn}>
            <div className={styles.top}>
              <h3>{intl.formatMessage({ id: 'homepage.perksPcitures.title' })}</h3>
            </div>
            <div className={styles.mid}>
              {imgArray.map((e, k) => (
                <div key={k} className={styles.h4W} role='presentation' onMouseEnter={(e) => {
                  setIndexImage(k)
                }}>
                  <h4>{e[intl.locale]}</h4>
                </div>
              ))}
            </div>
            <div className={styles.midMobile}>
              <CarouselPerks />
            </div>
            <div className={styles.bottom}>
              {intl.formatMessage({ id: 'homepage.perksPcitures.andMore' })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PictureAndList