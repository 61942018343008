// extracted by mini-css-extract-plugin
export var backImg = "Loader-module--backImg--eITkq";
export var bar = "Loader-module--bar--M-G1R";
export var center = "Loader-module--center--5Jhut";
export var deskImg = "Loader-module--deskImg--HG5GW";
export var mobImg = "Loader-module--mobImg--sqMsX";
export var percentage = "Loader-module--percentage--G-NQp";
export var progressContainer = "Loader-module--progressContainer--PJ93N";
export var scrollDown = "Loader-module--scrollDown--gMiPe";
export var sectionFullPicture = "Loader-module--sectionFullPicture--DrAGT";
export var titleAbsolute = "Loader-module--titleAbsolute--vdWOK";
export var topImg = "Loader-module--topImg--8denM";