import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./centralVideoWith4Texts.module.css"


const CentralVideoWith4Texts = () => {

  const intl = useIntl();

  return (
    <section className={styles.section}>
      <div className={styles.bufferUp}></div>
      <div className={styles.flexColumn}>
        <div className={styles.textContainer}>
          <div className={`${styles.absText} ${styles.topLeft}`}>
            <p>{intl.formatMessage({ id: 'homepage.videoWith4Texts.topLeftSmall' })}</p>
            <h4>{intl.formatMessage({ id: 'homepage.videoWith4Texts.topLeftBig' })}</h4>
          </div>
          <div className={`${styles.absText} ${styles.topRight}`}>
            <p>{intl.formatMessage({ id: 'homepage.videoWith4Texts.topRightSmall' })}</p>
            <h4>{intl.formatMessage({ id: 'homepage.videoWith4Texts.topRightBig' })}</h4>
          </div>
          <div className={`${styles.absText} ${styles.bottomLeft}`}>
            <h4>{intl.formatMessage({ id: 'homepage.videoWith4Texts.bottomLeftBig' })}</h4>
            <p>{intl.formatMessage({ id: 'homepage.videoWith4Texts.bottomLeftSmall' })}</p>
          </div>
          <div className={`${styles.absText} ${styles.bottomRight}`}>
            <h4>{intl.formatMessage({ id: 'homepage.videoWith4Texts.bottomRightBig' })}</h4>
            <p>{intl.formatMessage({ id: 'homepage.videoWith4Texts.bottomRightSmall' })}</p>
          </div>
        </div>
        <div>
        </div>
        <div className={styles.main}>
          <div className={styles.video}>
            <iframe
              title="Static shot of a bedroom with a moving curtain | Plan statique d'une chambre à coucher avec un rideau oscillant"
              src="https://player.vimeo.com/video/722306975?background=1&autoplay=1&loop=1&byline=0&title=0&dnt=1"
              allowfullscreen style={{ border: "0", overflow: "hidden" }}></iframe>
          </div>
        </div>
        <div>
        </div>
      </div>
      <div className={styles.bufferUp}></div>
    </section>
  )
}

export default CentralVideoWith4Texts
