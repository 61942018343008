import { useStaticQuery, graphql } from "gatsby"

let description = [
  {
    name: "Mansfield_008_L",
    en: "Conference rooms",
    fr: "Salles de conférences",
    en_alt: "Conference rooms Mansfield Condos",
    fr_alt: "Salle de conférences Condos Mansfield"
  },
  {
    name: "Mansfield_009_L",
    en: "Rooftop Pool",
    fr: "Piscine sur le toit",
    en_alt: "Pool of luxury penthouse for sale",
    fr_alt: "Piscine d'appartements de luxe à vendre"
  },
  {
    name: "Mansfield_010_L",
    en: "Fitness centre",
    fr: "Salle d'entraînement",
    en_alt: "Gym for prestigious condos in Montréal",
    fr_alt: "Salle d'entrainement pour condos de prestige à Montréal"
  },
  {
    name: "Mansfield_011_L",
    en: "Yoga room",
    fr: "Salle de yoga",
    en_alt: "Yoga studio for condos in downtown",
    fr_alt: "Studio de yoga pour condos au centre-ville"
  },
  {
    name: "Mansfield_012_L",
    en: "Golf simulator",
    fr: "Simulateur de golf",
    en_alt: "Golf simulators luxuriuos condos in Ville-Marie",
    fr_alt: "Simulateur de golf condos luxueux à Ville-Marie"
  },
  {
    name: "Mansfield_013_L",
    en: "Luxury spa",
    fr: "Luxueux spa",
    en_alt: "Luxurious spa in condos for sale",
    fr_alt: "Luxueux spa de condos à vendre"
  },
  {
    name: "Z_Mansfield_014_L",
    en: "Wine Bar",
    fr: "Bar à vin",
    en_alt: "Wine bar in luxurious appartments",
    fr_alt: "Bar à vin dans des appartements de luxe"
  },
  {
    name: "Mansfield_015_L",
    en: "Panoramic view",
    fr: "Vue panoramique",
    en_alt: "Panoramic view from Mansfield Condos",
    fr_alt: "Vue panoramique des condos Mansfield"
  }
]

const usePerksPictures = () => {
    const data = useStaticQuery(graphql`
      query PictureAndList {
        allFile(filter: {relativeDirectory: {eq: "homepage/perks"}}, sort: {fields: name}) {
          nodes {
            id
            name
            childImageSharp {
                gatsbyImageData(height: 1000, layout: CONSTRAINED)
            }
          }
        }
      }
    `);

    return data.allFile.nodes.map(node => ({
        ...node.childImageSharp,
        id: node.id,
        name: node.name,
        en: description.filter(d => d.name === node.name)[0].en,
        fr: description.filter(d => d.name === node.name)[0].fr,
        en_alt: description.filter(d => d.name === node.name)[0].en_alt,
        fr_alt: description.filter(d => d.name === node.name)[0].fr_alt,
    }));
};

export default usePerksPictures;