import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./centralVideo.module.css"


const CentralVideo = () => {

  const intl = useIntl();

  return (
    <section className={styles.section}>
      {/* <div className={styles.bufferUp}></div> */}
      <div className={styles.flexColumn}>
        <div className={styles.top}>
          <p>{intl.formatMessage({ id: 'homepage.centeredVideoBuilding.top' })}</p>
        </div>
        <div className={styles.topMobile}>
          <p>
            {intl.formatMessage({ id: 'homepage.centeredVideoBuilding.mobile.top1' })}
            <br />
            {intl.formatMessage({ id: 'homepage.centeredVideoBuilding.mobile.top2' })}
          </p>
        </div>
        <div className={styles.main}>
          <div className={styles.video}>
            <iframe
              title="Timelapse of Montreal's sky | Timelapse du ciel de Montréal"
              src="https://player.vimeo.com/video/725664449?background=1&autoplay=1&loop=1&byline=0&title=0&dnt=1"
              allowfullscreen style={{ border: "0", overflow: "hidden" }}></iframe>
          </div>
        </div>
        <div className={styles.bottom}>
          <p>{intl.formatMessage({ id: 'homepage.centeredVideoBuilding.bottom' })}</p>
        </div>
        <div className={styles.bottomMobile}>
          <p>
            {intl.formatMessage({ id: 'homepage.centeredVideoBuilding.mobile.bottom1' })}
            <br />
            {intl.formatMessage({ id: 'homepage.centeredVideoBuilding.mobile.bottom2' })}
          </p>
        </div>
      </div>
      {/* <div className={styles.bufferUp}></div> */}
    </section>
  )
}

export default CentralVideo
