// extracted by mini-css-extract-plugin
export var bottom = "sectionPictureCenteredAndVideo-module--bottom--oVofc";
export var imgBack = "sectionPictureCenteredAndVideo-module--imgBack--AAB7-";
export var imgTop = "sectionPictureCenteredAndVideo-module--imgTop--FqnD5";
export var left = "sectionPictureCenteredAndVideo-module--left--hQYbi";
export var mainRow = "sectionPictureCenteredAndVideo-module--mainRow--qTfDa";
export var mid = "sectionPictureCenteredAndVideo-module--mid--y0qZJ";
export var midDiv = "sectionPictureCenteredAndVideo-module--midDiv--Zl1EB";
export var right = "sectionPictureCenteredAndVideo-module--right--4aNYt";
export var rightColumn = "sectionPictureCenteredAndVideo-module--rightColumn--yEjxe";
export var section = "sectionPictureCenteredAndVideo-module--section--4hC77";
export var top = "sectionPictureCenteredAndVideo-module--top--Ijcuw";
export var video = "sectionPictureCenteredAndVideo-module--video--p-kYY";