// extracted by mini-css-extract-plugin
export var bottom = "sectionVideoAndPictureCentered-module--bottom--bFoDl";
export var imgBack = "sectionVideoAndPictureCentered-module--imgBack--YhCGR";
export var imgTop = "sectionVideoAndPictureCentered-module--imgTop--K6YkU";
export var left = "sectionVideoAndPictureCentered-module--left--Xod3f";
export var mainRow = "sectionVideoAndPictureCentered-module--mainRow--X-6pr";
export var mid = "sectionVideoAndPictureCentered-module--mid--OSdJi";
export var midDiv = "sectionVideoAndPictureCentered-module--midDiv--3UQnq";
export var right = "sectionVideoAndPictureCentered-module--right--rkdqQ";
export var rightColumn = "sectionVideoAndPictureCentered-module--rightColumn--Vt2pf";
export var section = "sectionVideoAndPictureCentered-module--section--RR0ll";
export var top = "sectionVideoAndPictureCentered-module--top--JwHGa";
export var video = "sectionVideoAndPictureCentered-module--video--FN7yP";