import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./sectionVideoAndPictureCentered.module.css"
import { StaticImage } from "gatsby-plugin-image"

const SectionVideoAndPictureCentered = () => {

  const intl = useIntl();

  return (
    <section className={styles.section}>
      <div className={`row ${styles.mainRow}`}>
        <div className={`col-xl-6 ${styles.left} d-none d-xl-flex`}>
          <div className={styles.video}>
            <iframe
              title="Shadows moving on chairs on the balcony | Les ombres qui bougent sur des chaises sur le balcon"
              src="https://player.vimeo.com/video/722680725?background=1&autoplay=1&loop=1&byline=0&title=0&dnt=1"
              allowfullscreen style={{ border: "0", overflow: "hidden" }}></iframe>
          </div>
        </div>
        <div className={`col-xl-6 ${styles.right}`}>
          <div className={styles.rightColumn}>
            <div className={styles.top}>
              <p>
                {intl.formatMessage({ id: 'homepage.videoAndCenteredPicture.top1' })}
                <br className="d-none d-sm-inline" />
                {intl.formatMessage({ id: 'homepage.videoAndCenteredPicture.top2' })}
              </p>
            </div>
            <div className={styles.mid}>
              <div className={styles.midDiv}>
                <StaticImage
                  className={styles.imgBack}
                  src='../images/homepage/Mansfield_003_image_bg_L.png'
                  loading="eager"
                  placeholder="blurred"
                  layout="constrained"
                  height={500}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt={intl.formatMessage({ id: 'alts.artwork' })}
                />
                <StaticImage
                  className={styles.imgTop}
                  src='../images/homepage/Mansfield_003_image_top_L.png'
                  loading="eager"
                  placeholder="blurred"
                  layout="constrained"
                  height={500}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt={intl.formatMessage({ id: 'alts.artwork' })}
                />
                <div className="number-wrapper">
                  <div className="number">
                    <div id="nbr-2-1" className="nbr-wrapper"> <span className="nbr">4</span> </div>
                    <div id="nbr-2-2" className="nbr-wrapper"> <span className="nbr">9</span> </div>
                    <div id="nbr-2-3" className="nbr-wrapper"> <span className="nbr">0</span> </div> <span>.</span>
                    <div id="nbr-2-4" className="nbr-wrapper"> <span className="nbr">5</span> </div>
                    <div id="nbr-2-5" className="nbr-wrapper"> <span className="nbr">4</span> </div>
                    <div id="nbr-2-6" className="nbr-wrapper"> <span className="nbr">5</span> </div> <span>.</span>
                    <div id="nbr-2-7" className="nbr-wrapper"> <span className="nbr">7</span> </div>
                    <div id="nbr-2-8" className="nbr-wrapper"> <span className="nbr">8</span> </div>
                    <div id="nbr-2-9" className="nbr-wrapper"> <span className="nbr">9</span> </div> <small>$</small>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <p>
                {intl.formatMessage({ id: 'homepage.videoAndCenteredPicture.bottom1' })}
                <br />
                {intl.formatMessage({ id: 'homepage.videoAndCenteredPicture.bottom2' })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionVideoAndPictureCentered
