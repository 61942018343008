import * as React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"
import Home from "../components/pages/Home"

import { useIntl } from "gatsby-plugin-intl-v4"

const IndexPage = () => {

  const intl = useIntl();

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'h1.index' })}
        description={intl.formatMessage({ id: 'seo.description' })}
        pageName={''}
      />
      <Home />
    </Layout>
  )
}

export default IndexPage
