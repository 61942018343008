// extracted by mini-css-extract-plugin
export var bottom = "pictureAndList-module--bottom--mLtrZ";
export var h4W = "pictureAndList-module--h4W--wo6oj";
export var left = "pictureAndList-module--left--vsjQ+";
export var limitWidth = "pictureAndList-module--limitWidth--xfpIu";
export var mainRow = "pictureAndList-module--mainRow--fbOj7";
export var mid = "pictureAndList-module--mid--drzHi";
export var midMobile = "pictureAndList-module--midMobile--jii3q";
export var preview = "pictureAndList-module--preview--XGZ4-";
export var previewImage = "pictureAndList-module--previewImage--1s4y7";
export var right = "pictureAndList-module--right--fBtnH";
export var rightColumn = "pictureAndList-module--rightColumn--yTXkA";
export var section = "pictureAndList-module--section--i+cxx";
export var top = "pictureAndList-module--top--DoK1x";