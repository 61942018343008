// extracted by mini-css-extract-plugin
export var absText = "centralVideoWith4Texts-module--absText--Mq-KZ";
export var bottomLeft = "centralVideoWith4Texts-module--bottomLeft--8OSzY";
export var bottomRight = "centralVideoWith4Texts-module--bottomRight--roCf6";
export var bufferUp = "centralVideoWith4Texts-module--bufferUp--OuTxH";
export var flexColumn = "centralVideoWith4Texts-module--flexColumn---Hcg4";
export var main = "centralVideoWith4Texts-module--main---+1+M";
export var section = "centralVideoWith4Texts-module--section--SBSIA";
export var textContainer = "centralVideoWith4Texts-module--textContainer--eDGmY";
export var topLeft = "centralVideoWith4Texts-module--topLeft--7UVnR";
export var topRight = "centralVideoWith4Texts-module--topRight--kJnpW";
export var video = "centralVideoWith4Texts-module--video--f4aA2";