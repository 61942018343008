import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./sectionPictureCenteredAndVideo.module.css"
import { StaticImage } from "gatsby-plugin-image"

const SectionPictureCenteredAndVideo = () => {

  const intl = useIntl();

  return (
    <section className={styles.section}>
      <div className={`row ${styles.mainRow}`}>
        <div className={`col-xl-6 ${styles.left} d-none d-xl-flex`}>
          <div className={styles.video}>
            <iframe
              title="Shadows moving on the living room of a condo | Les ombres qui bougent sur le salon d'un condo"
              src="https://player.vimeo.com/video/722307003?background=1&autoplay=1&loop=1&byline=0&title=0&dnt=1"
              allowfullscreen style={{ border: "0", overflow: "hidden" }}></iframe>
          </div>
        </div>
        <div className={`col-xl-6 ${styles.right} order-first`}>
          <div className={styles.rightColumn}>
            <div className={styles.top}>
              <p>
                {intl.formatMessage({ id: 'homepage.centeredPictureAndVideo.top1' })}
                <br />
                {intl.formatMessage({ id: 'homepage.centeredPictureAndVideo.top2' })}
              </p>
            </div>
            <div className={styles.mid}>
              <div className={styles.midDiv}>
                <StaticImage
                  className={styles.imgBack}
                  src='../images/homepage/Mansfield_002_image-bg-L.png'
                  loading="eager"
                  placeholder="blurred"
                  layout="constrained"
                  height={500}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt={intl.formatMessage({ id: 'alts.artwork' })}
                />
                <StaticImage
                  className={styles.imgTop}
                  src='../images/homepage/Mansfield_002_image-top-L.png'
                  loading="eager"
                  placeholder="blurred"
                  layout="constrained"
                  height={500}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt={intl.formatMessage({ id: 'alts.artwork' })}
                />
                <div className="number-wrapper">
                  <div className="number">
                    <div id="nbr-1" className="nbr-wrapper"> <span className="nbr">1</span> </div>
                    <div id="nbr-2" className="nbr-wrapper"> <span className="nbr">6</span> </div>
                    <div id="nbr-3" className="nbr-wrapper"> <span className="nbr">0</span> </div> <span>.</span>
                    <div id="nbr-4" className="nbr-wrapper"> <span className="nbr">5</span> </div>
                    <div id="nbr-5" className="nbr-wrapper"> <span className="nbr">4</span> </div>
                    <div id="nbr-6" className="nbr-wrapper"> <span className="nbr">8</span> </div> <span>.</span>
                    <div id="nbr-7" className="nbr-wrapper"> <span className="nbr">7</span> </div>
                    <div id="nbr-8" className="nbr-wrapper"> <span className="nbr">9</span> </div>
                    <div id="nbr-9" className="nbr-wrapper"> <span className="nbr">0</span> </div> <small>$</small>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <p>
                {intl.formatMessage({ id: 'homepage.centeredPictureAndVideo.bottom1' })}
                <br />
                {intl.formatMessage({ id: 'homepage.centeredPictureAndVideo.bottom2' })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionPictureCenteredAndVideo
