import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./carouselPerks.module.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import usePerksPictures from "../usePerksPictures"

const CarouselPerks = () => {

  const intl = useIntl();
  const imgArray = usePerksPictures();

  return (
    <div id="carouselPerksWrapper" className={`carousel carousel-dark slide ${styles.wrapper}`} data-bs-touch="true" data-bs-interval="false">
      <div className={`carousel-indicators ${styles.indicators}`}>
        {imgArray.map((element, index) => {
          return (
            <button key={index} type="button" data-bs-target="#carouselPerksWrapper" data-bs-slide-to={`${index}`} className={`${index === 0 ? 'active' : ''}`} aria-current={`${index === 0 ? 'true' : 'false'}`} aria-label={element[intl.locale]}></button>
          )
        })}
      </div>
      <div className={`carousel-inner ${styles.inner}`}>
        {imgArray.map((element, index) => {
          return (<div key={index} className={`carousel-item ${index === 0 ? 'active' : ''} ${styles.item}`}>
            <GatsbyImage
              image={getImage(element)}
              loading="eager"
              alt={element[intl.locale + "_alt"]}
              placeholder="none"
              layout="constrained"
              quality={75}
              className={styles.carouselImg}
            />
            <div className={`carousel-caption ${styles.caption}`}>
              <p>{element[intl.locale]}</p>
            </div>
          </div>)
        })}
      </div>
      <button className={`carousel-control-prev ${styles.arrow}`} type="button" data-bs-target="#carouselPerksWrapper" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className={`carousel-control-next ${styles.arrow}`} type="button" data-bs-target="#carouselPerksWrapper" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default CarouselPerks