// extracted by mini-css-extract-plugin
export var buttonLinkSep = "textWithImagesInline-module--buttonLinkSep--2En-q";
export var coloredSpan = "textWithImagesInline-module--coloredSpan--KZ56M";
export var flexColumn = "textWithImagesInline-module--flexColumn--MAAFf";
export var hoverZone = "textWithImagesInline-module--hoverZone--0krNu";
export var hovered = "textWithImagesInline-module--hovered--cqKLp";
export var link = "textWithImagesInline-module--link--6T-aK";
export var main = "textWithImagesInline-module--main--dM7W+";
export var paragraph = "textWithImagesInline-module--paragraph--yCWrA";
export var section = "textWithImagesInline-module--section--GDLJU";
export var spanImg = "textWithImagesInline-module--spanImg--v9+-N";
export var spanWW = "textWithImagesInline-module--spanWW--3Nf8K";
export var spanWrapper = "textWithImagesInline-module--spanWrapper--gl4Vm";
export var staticImg = "textWithImagesInline-module--staticImg--fGfA6";
export var title = "textWithImagesInline-module--title--ZfrQk";