// extracted by mini-css-extract-plugin
export var bg = "Home-module--bg--+kakK";
export var buttonBall = "Home-module--buttonBall--HrfLI";
export var closeModal = "Home-module--closeModal--HdDG7";
export var cross = "Home-module--cross--D8wrr";
export var fixedConstructionButton = "Home-module--fixedConstructionButton--Xcf1C";
export var footerImg = "Home-module--footerImg--0hTzQ";
export var hide = "Home-module--hide---VHYD";
export var iframeContainer = "Home-module--iframeContainer--rORUf";
export var modalConstruction = "Home-module--modalConstruction--SRAR+";
export var sectionFullPicture = "Home-module--sectionFullPicture--efStw";
export var sectionLastImage = "Home-module--sectionLastImage--yFtSJ";
export var sectionTextAndImages = "Home-module--sectionTextAndImages--Izvdc";
export var titleAbsolute = "Home-module--titleAbsolute--ssl5c";
export var titleHalfScreen = "Home-module--titleHalfScreen--ne78F";