import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./centralVideoWithBackground.module.css"

import LogoSVG from '../assets/svg/txt_mansfield.svg'
import ArrowDownSVG from '../assets/svg/double-caret-down.svg'

const CentralVideoWithBackground = () => {

  const intl = useIntl();

  return (
    <section className={`${styles.section} active`}>
      <LogoSVG className={styles.titleAbsolute} />
      <div className={styles.bufferUp}></div>
      <div className={styles.flexColumn}>
        <div className={styles.main}>
          <div className={styles.video}>
            <iframe
              title="Mansfield presentation video | Vidéo de présentation Mansfield"
              src="https://player.vimeo.com/video/720802221?background=1&autoplay=1&loop=1&byline=0&title=0&dnt=1"
              allowfullscreen style={{ border: "0", overflow: "hidden" }}></iframe>
          </div>
          <div className={styles.videoMobile}>
            <iframe
              title="Mansfield presentation video for Mobile | Vidéo de présentation Mansfield pour téléphones"
              src="https://player.vimeo.com/video/764339539?background=1&autoplay=1&loop=1&byline=0&title=0&dnt=1"
              allowfullscreen style={{ border: "0", overflow: "hidden" }}></iframe>
          </div>
        </div>
      </div>
      <div className={styles.scrollDown}>
        <h2>{intl.formatMessage({ id: 'homepage.seoH2' })}</h2>
        <h3>{intl.formatMessage({ id: 'occupency2024' })}</h3>
        <ArrowDownSVG height={10} width={10} />
      </div>
    </section>
  )
}

export default CentralVideoWithBackground
