import * as React from "react"
import * as styles from "./Loader.module.css"
import { StaticImage } from "gatsby-plugin-image"

import { useIntl } from "gatsby-plugin-intl-v4"


const Loader = ({ id }) => {

    const intl = useIntl();

    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const updateProgress = () => setProgress(progress + 0.5);
        if (progress < 100) {
            setTimeout(updateProgress, 15);
        }
    }, [progress]);

    return (
        <div id={id} className={styles.sectionFullPicture}>
            <StaticImage
                className={styles.mobImg}
                src='../images/homepage/Mansfield_image_00_mobile.jpg'
                loading="eager"
                layout="fullWidth"
                quality={66}
                formats={["auto", "webp", "avif"]}
                alt={intl.formatMessage({ id: 'alts.livingRoom' })}
                placeholder="dominantColor"
                style={{
                    height: "100%",
                    maxWidth: "100%"
                }}
            />
            <StaticImage
                className={styles.deskImg}
                src='../images/homepage/Mansfield_image_00_desktop.jpg'
                loading="eager"
                layout="fullWidth"
                quality={66}
                formats={["auto", "webp", "avif"]}
                alt={intl.formatMessage({ id: 'alts.livingRoom' })}
                placeholder="dominantColor"
                style={{
                    height: "100%",
                    maxWidth: "100%"
                }}
            />
            <div className={styles.progressContainer}>
                <div className={styles.center}>
                    <div className={styles.bar} style={{
                        width: progress * 3 + "px"
                    }}>
                    </div>
                    <div className={styles.percentage}>
                        {Math.floor(progress) + "%"}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Loader
